<template>
  <div class="faq container">
    <section>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="비밀번호를 잃어버렸어요." name="1">
          <div>첫 페이지 로그인 화면의 비밀번호 찾기에서 신규 비밀번호로 변경할 수 있습니다.</div>
        </el-collapse-item>
        <el-collapse-item title="비밀번호를 변경하고 싶어요." name="2">
          <div>마이페이지 화면의 <router-link to="/mypage/changePw">[비밀번호 변경]</router-link>에서 신규비밀번호로 변경할 수 있습니다.</div>
        </el-collapse-item>
        <el-collapse-item title="휴대전화 번호가 바뀌었어요." name="3">
          <div>마이페이지 화면의 <router-link to="/mypage/userInfo">[회원 정보]</router-link>에서 휴대전화 번호를 변경할 수 있습니다.</div>
        </el-collapse-item>
        <el-collapse-item title="농장관리 화면에서 날씨정보가 보이지 않아요." name="4">
          <div>
            날씨정보는 기상청_단기예보 조회서비스의 공공 데이터를 사용합니다.<br />일시적인 접속연결이 불완전해서 날씨정보가 보이지 않을 수 있습니다. 잠시 후 다시 확인해 보시고 계속 문제가 있으면 앱
            관리자에게 연락 바랍니다.<br />
            <a class="kakaolink" href="http://pf.kakao.com/_ixffVxj/chat" target="_blank">카카오톡 채팅 문의 - 갭노트</a>
          </div>
        </el-collapse-item>
        <el-collapse-item title="농장관리 화면에서 시설내 대표 값(시설 내 센서장치 값)이 보이지 않아요." name="5">
          <div>
            각 농장의 시설 내 설치한 센서장치에 전원이 정상으로 공급되고 있는지 먼저 확인 바랍니다. <br />센서장치 전원에 문제 없는데도 센서의 데이터 값이 보이 않으면 앱 관리자에게 연락 바랍니다.<br />
            <a class="kakaolink" href="http://pf.kakao.com/_ixffVxj/chat" target="_blank">카카오톡 채팅 문의 - 갭노트</a>
          </div>
        </el-collapse-item>
        <el-collapse-item title="수확량 예상 화면에서 온도, 습도, CO2 값의 그림이 보이지 않아요." name="6">
          <div>
            각 농장의 시설 내 설치한 센서장치에 전원이 정상으로 공급되고 있는지 먼저 확인 바랍니다.<br />센서장치 전원에 문제 없는데도 센서의 데이터 값이 보이 않으면 앱 관리자에게 연락 바랍니다.<br />
            <a class="kakaolink" href="http://pf.kakao.com/_ixffVxj/chat" target="_blank">카카오톡 채팅 문의 - 갭노트</a>
          </div>
        </el-collapse-item>
      </el-collapse>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return { activeNames: "" };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 4);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "자주 하는 질문 (FAQ)");
  },
  mounted() {},
  methods: {},
};
</script>
